<template>
  <div>
    <sb-spacer height="20" />

    <sb-divider title="Wachtwoord wijzigen" />

    <sb-spacer height="20" />

    <i-form
      ref="formSettings"
      :model="formSettings"
      :rules="validateEditPassword"
      :label-width="200"
    >
      <form-item label="Nieuw wachtwoord" prop="password">
        <i-input v-model="formSettings.password" type="password" />
      </form-item>

      <form-item label="Herhaal wachtwoord" prop="passwordConfirmation">
        <i-input v-model="formSettings.passwordConfirmation" type="password" />
      </form-item>

      <sb-spacer height="30" />

      <form-item label="Tijdelijk wachtwoord?">
        <sb-info>
          <p>
            Met een tijdelijk wachtwoord wordt de gebruiker tijdens het inloggen
            gevraagd een eigen wachtwoord in te stellen.
          </p>
        </sb-info>
        <i-switch v-model="formSettings.isTemporary">
          <span slot="open">Ja</span>
          <span slot="close">Nee</span>
        </i-switch>
      </form-item>
    </i-form>

    <sb-spacer height="300" />

    <sb-bottom-fixed-buttons>
      <template #right>
        <i-button
          type="primary"
          size="large"
          @click="handleSubmit('formSettings')"
        >
          Wijzigen
        </i-button>
      </template>
      <!-- <i-button
        style="margin-left: 8px"
        size="large"
      >
        Annuleren
      </i-button> -->
    </sb-bottom-fixed-buttons>
  </div>
</template>

<script>
import SbDivider from '@/components/SbDivider';
import SbBottomFixedButtons from '@/components/SbBottomFixedButtons';
import SbInfo from '@/components/SbInfo';

import SetPassword from '@/graphql/mutations/SetPassword.gql';

export default {
  components: {
    SbBottomFixedButtons,
    SbDivider,
    SbInfo,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    const validatePasswordCheck = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Herhaal het wachtwoord'));
      } else if (value !== this.formSettings.password) {
        callback(new Error('De twee wachtwoorden zijn niet hetzelfde'));
      } else {
        callback();
      }
    };
    return {
      formSettings: {
        password: '',
        passwordConfirmation: '',
        isTemporary: true,
      },
      validateEditPassword: {
        password: [
          {
            required: true,
            message: 'Voer een wachtwoord in',
            trigger: 'blur',
          },
          {
            type: 'string',
            min: 8,
            message: 'Voer minimaal 8 karakters in',
            trigger: 'blur',
          },
        ],
        passwordConfirmation: [
          {
            required: true,
            validator: validatePasswordCheck,
            trigger: 'blur',
          },
        ],
      },
    };
  },
  methods: {
    handleSubmit(name) {
      console.log('user id', this.data.id);
      this.$refs[name].validate(async (valid) => {
        if (valid) {
          const { password, isTemporary } = this.formSettings;
          const { data } = await this.$apollo.mutate({
            mutation: SetPassword,
            variables: {
              input: {
                userId: this.data.id,
                newPassword: password,
                isTemporary,
              },
            },
          });
          if (data.setPassword) {
            this.$Message.success('Het wijzigen van het wachtwoord is gelukt');
            this.$refs.formSettings.resetFields();
          } else {
            console.log(data);
            this.$Message.console.error(
              'Het wijzigen van het wachtwoord is niet gelukt',
            );
          }
          // this.$Message.success('Success!');
        } else {
          this.$Message.error(
            'Er zijn een aantal fouten, controleer je invoer.',
          );
        }
      });
    },
  },
};
</script>

<style></style>
