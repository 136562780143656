<template>
  <div>
    <sb-page-header>
      <template v-slot:left>
        <h1>Schoolbeheerders</h1>
      </template>
      <template v-slot:right>
        <router-link
          :to="{
            name: 'ResourceManagementManagerAdd',
            query: { organisationId: organisationId },
          }"
        >
          <i-button type="primary" ghost>
            Schoolbeheerder toevoegen

            <icon type="md-add" />
          </i-button>
        </router-link>
      </template>
    </sb-page-header>
    <sb-managers-table :organisation-id="organisationId || resourceId" />
  </div>
</template>

<script>
import SbPageHeader from '@/components/SbPageHeader';

import SbManagersTable from '@/components/SbManagersTable';

export default {
  components: {
    SbPageHeader,
    SbManagersTable,
  },
  props: {
    resourceId: {
      type: String,
      default: null,
    },
    organisationId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {},
  mounted() {
    console.log('resourceId', this.resourceId);
  },
};
</script>

<style></style>
