<template>
  <div v-if="user">
    <sb-loading v-if="archiver.loading" />

    <sb-page-header>
      <template v-slot:left>
        <h1>
          {{ user.firstName }} {{ user.prefix }} {{ user.lastName }}
          <small>Beheerder</small>
        </h1>
      </template>
      <template v-slot:right>
        <dropdown placement="bottom-end">
          <i-button type="primary" ghost>
            Acties
            <icon type="md-more" />
          </i-button>

          <dropdown-menu slot="list" style="width: 220px">
            <router-link :to="{ name: 'ResourceManagementManagerEdit' }">
              <sb-dropdown-button> Gegevens bewerken </sb-dropdown-button>
            </router-link>
            <router-link
              :to="{ name: 'ResourceManagementManagerEditPassword' }"
            >
              <sb-dropdown-button> Wachtwoord wijzigen </sb-dropdown-button>
            </router-link>
            <sb-dropdown-button style="color: red" @click.native="archiveUser">
              Gebruiker archiveren
            </sb-dropdown-button>
          </dropdown-menu>
        </dropdown>
      </template>
    </sb-page-header>

    <sb-tabbed-page v-if="user" :data="user" />
  </div>
</template>

<script>
import SbLoading from '@/components/SbLoading';
import SbPageHeader from '@/components/SbPageHeader';
import SbTabbedPage from '@/components/SbTabbedPage';
import GetUserById from '@/graphql/queries/GetUserById.gql';
import { UserArchiver } from '@/lib/user-archiver';

export default {
  name: 'OnlineStudent',

  components: {
    SbPageHeader,
    SbTabbedPage,
    SbLoading,
  },

  props: {
    managerId: {
      type: String,
      default: null,
    },
  },

  created() {
    this.archiver = new UserArchiver(this)
      .setUserTypeLabel('Beheerder')
      .on('success', this.$router.navigateBack);
  },

  methods: {
    async archiveUser() {
      await this.archiver.archiveUser(this.user.id);
    },
  },

  apollo: {
    user: {
      skip() {
        return !this.managerId;
      },
      query: GetUserById,
      variables() {
        return {
          id: this.managerId,
        };
      },
      update(data) {
        console.log(data);
        if (!data.getUserById) {
          this.$Modal.warning({
            title: 'Melding',
            content: 'Deze gebruiker is niet gevonden',
            onOk: () => {
              this.$router.navigateBack();
            },
          });
        }
        return data.getUserById;
      },
    },
  },
};
</script>

<style lang="scss"></style>
