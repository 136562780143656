import ArchiveUsers from '@/graphql/mutations/ArchiveUsers.gql';
import Vue from 'vue';

export class UserArchiver {
  constructor(vm) {
    if (!vm) throw new Error('Missing `vm`. Vue component is required');
    this.vm = vm;
    this.state = Vue.observable({ loading: false });
    this.userTypeLabel = 'Gebruiker';
    this.eventHandlers = {};
  }

  setUserTypeLabel(userTypeLabel) {
    this.userTypeLabel = userTypeLabel;
    return this;
  }

  on(event, callback) {
    this.eventHandlers[event] = callback;
    return this;
  }

  confirmArchiveUser = () => {
    return new Promise((resolve) => {
      this.vm.$Modal.confirm({
        title: 'Let op!',
        content: `Weet je zeker dat je deze ${this.userTypeLabel} wilt archiveren?`,
        closable: true,
        okText: 'Ja',
        onOk: () => resolve(true),
      });
    });
  };

  archiveUser = (id) => {
    this.archiveUsers([id]);
  };

  archiveUsers = async (ids) => {
    if (await this.confirmArchiveUser()) {
      this.state.loading = true;

      try {
        const { data } = await this.vm.$apollo.mutate({
          mutation: ArchiveUsers,
          variables: {
            input: {
              userIds: ids,
            },
          },
        });

        this.state.loading = false;
        this.user = null;

        if (data.archiveUsers) {
          this.vm.$Message.success(`${this.userTypeLabel} gearchiveerd.`);
          this.eventHandlers.success?.call(this.vm);
        }
      } catch (error) {
        const message = error?.message?.replace?.('GraphQL error: ', '');
        const content = [
          message ? `<p><i>${message}</i></p>` : false,
          'Probeer het later nog eens.',
        ]
          .filter(Boolean)
          .join('');

        this.state.loading = false;
        this.vm.$Modal.error({
          title: 'Er ging iets fout',
          content,
        });
      }
    }
  };
}
